import React, { useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import CounselorCalendar from "./CounselorCalendar";
import CounselorCalendarModal from "./CounselorCalendarModal";
import attendeeImage from "../../../assets/image/png/Avatar.png";

const start = new Date();
const end = new Date(new Date().setMinutes(start.getMinutes() + 60));

const attendees = [
  {
    id: "1",
    name: "Chin",
    attendeeImage: attendeeImage,
    role: "Athlete",
  },
  { id: "2", name: "Khanh", attendeeImage: attendeeImage, role: "Coach" },
  { id: "3", name: "Linh", attendeeImage: attendeeImage, role: "Athlete" },
  { id: "4", name: "Hai", attendeeImage: attendeeImage, role: "Coach" },
];

const schedules = [];

const colors = [
  {
    id: "1",
    color: "#4D5E80",
    bgColor: "#FF66330D",
    dragBgColor: "#FF6633",
    borderColor: "#FF6633",
  },
  {
    id: "2",
    color: "#4D5E80",
    bgColor: "#29CC390D",
    dragBgColor: "#29CC39",
    borderColor: "#29CC39",
  },
  {
    id: "3",
    color: "#4D5E80",
    bgColor: "#8833FF0D",
    dragBgColor: "#8833FF",
    borderColor: "#8833FF",
  },
  {
    id: "4",
    color: "#4D5E80",
    bgColor: "#33BFFF0D",
    dragBgColor: "#33BFFF",
    borderColor: "#33BFFF",
  },
  {
    id: "5",
    color: "#4D5E80",
    bgColor: "#FFCB330D",
    dragBgColor: "#FFCB33",
    borderColor: "#FFCB33",
  },
  {
    id: "6",
    color: "#4D5E80",
    bgColor: "#E62E7B0D",
    dragBgColor: "#E62E7B",
    borderColor: "#E62E7B",
  },
  {
    id: "7",
    color: "#4D5E80",
    bgColor: "#2EE6CA0D",
    dragBgColor: "#2EE6CA",
    borderColor: "#2EE6CA",
  },
];

const Counsel = () => {
  const [modal, setModal] = useState(false);
  const [event, setEvent] = useState(null);
  const childRef = useRef();

  const toggle = () => {
    setModal(!modal);
    setEvent(null);
  };

  function onBeforeCreateSchedule(event) {
    event.guide.clearGuideElement();
    setModal(true);
    setEvent(event);
  }
  function handleCreateSchedule(newEvent) {
    if (event) {
      const color = colors[Math.floor(Math.random() * colors.length)];
      const newSchedule = {
        ...event,
        id: uuidv4(),
        title: newEvent.title,
        calendarId: newEvent.calendarId,
        category: event.isAllDay ? "allday" : "time",
        attendees: newEvent.attendees,
        isVisible: true,
        start: newEvent.start,
        end: newEvent.end,
        isAllDay: event.isAllDay,
        dueDateClass: "",
        location: event.location,
        state: event.state,
        body: newEvent.body,
        color: color.dragBgColor,
        bgColor: color.bgColor,
        borderColor: color.borderColor,
        dragBgColor: color.dragBgColor,
      };
      childRef.current.createSchedule(newSchedule);
      setModal(false);
    }
  }

  const calendars = [];

  function onBeforeUpdateSchedule(event) {
    console.log("onBeforeUpdateSchedule", event);

    const { schedule, changes } = event;

    if (changes) {
      const result = true;
      if (result) {
        return childRef.current.updateSchedule(schedule, changes);
      }
    }

    setModal(true);
    setEvent(event);
  }

  async function handleUpdateSchedule(updateEvent) {
    const result = true; // Assume some logic to determine success/failure

    if (result) {
      const { schedule } = event; // Original schedule from state

      // Delete the old schedule
      await childRef.current.deleteSchedule(schedule);

      // Create a new schedule with updated details
      const newSchedule = {
        ...schedule,
        id: schedule.id,
        title: updateEvent.title,
        calendarId: updateEvent.calendarId,
        category: event.isAllDay ? "allday" : "time",
        attendees: updateEvent.attendees,
        isVisible: true,
        start: updateEvent.start,
        end: updateEvent.end,
        isAllDay: event.isAllDay,
        dueDateClass: "",
        location: updateEvent.location,
        state: updateEvent.state,
        body: updateEvent.body,
        color: schedule.color,
        bgColor: schedule.bgColor,
        borderColor: schedule.borderColor,
        dragBgColor: schedule.dragBgColor,
      };

      console.log("Updated Schedule", newSchedule);

      // Create the new schedule
      await childRef.current.createSchedule(newSchedule);

      // Close the modal
      setModal(false);
    }
  }

  function onBeforeDeleteSchedule(event) {
    const result = true;

    if (result) {
      const { schedule } = event;
      childRef.current.deleteSchedule(schedule);
    }

    return true;
  }

  const formatCalendars = calendars.map((element) => ({
    ...colors.find((element2) => element2.id === element.id),
    ...element,
  }));

  return (
    <div>
      <CounselorCalendar
        ref={childRef}
        {...{
          isReadOnly: false,
          showSlidebar: true,
          showMenu: true,
          useCreationPopup: false,
          calendars: formatCalendars,
          schedules,
          onBeforeCreateSchedule,
          onBeforeUpdateSchedule,
          onBeforeDeleteSchedule,
        }}
      />
      <CounselorCalendarModal
        isOpen={modal}
        toggle={toggle}
        onSubmit={
          event?.triggerEventName === "mouseup"
            ? handleCreateSchedule
            : handleUpdateSchedule
        }
        submitText={event?.triggerEventName === "mouseup" ? "Save" : "Update"}
        calendars={formatCalendars}
        attendees={attendees}
        schedule={event?.schedule}
        startDate={event?.start}
        endDate={event?.end}
        body={event?.schedule?.body}
      />
    </div>
  );
};

export default Counsel;
