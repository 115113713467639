import React from "react";
import GenerateLink from "./GenerateLink/GenerateLink";
import Sessions from "./Sessions/Sessions";

const Session = () => {
  return (
    <div className="pt-4">
      <GenerateLink />
      <Sessions />
    </div>
  );
};

export default Session;
