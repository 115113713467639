import "./Sessions.css";
import React, { useState } from "react";
import { Button, Card, Image } from "antd";
import { Typography } from "antd";
import laptopWithClock from "../../../../assets/image/png/watchOnLaptop.png";
import { Col, Row } from "react-bootstrap";

const Sessions = () => {
  const [activeTab, setActiveTab] = useState("All");
  const { Title, Text } = Typography;
  const [data, setData] = useState([
    {
      img: laptopWithClock,
      title: "Wellness Mastery with Coach",
      time: "12:30 PM",
      date: "11/03/2025",
    },
    {
      img: laptopWithClock,
      title: "Wellness Mastery with Coach",
      time: "12:30 PM",
      date: "11/03/2025",
    },
    {
      img: laptopWithClock,
      title: "Wellness Mastery with Coach",
      time: "12:30 PM",
      date: "11/03/2025",
    },
    {
      img: laptopWithClock,
      title: "Wellness Mastery with Coach",
      time: "12:30 PM",
      date: "11/03/2025",
    },
  ]);

  const handleButtonClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="pb-5 pt-4">
      <div className="d-flex align-items-center gap-4 mt-4">
        <Button
          className={`btn-sessions-tab w-100 fs-15 ${
            activeTab === "All"
              ? "btn-sessions-tab-active ff-gotham-bold"
              : "ff-gotham-normal"
          }`}
          onClick={() => handleButtonClick("All")}
        >
          All
        </Button>
        <Button
          className={`btn-sessions-tab w-100 fs-15 ${
            activeTab === "Recent"
              ? "btn-sessions-tab-active ff-gotham-bold"
              : "ff-gotham-normal"
          }`}
          onClick={() => handleButtonClick("Recent")}
        >
          Recent
        </Button>
        <Button
          className={`btn-sessions-tab w-100 fs-15 ${
            activeTab === "Upcoming"
              ? "btn-sessions-tab-active ff-gotham-bold"
              : "ff-gotham-normal"
          }`}
          onClick={() => handleButtonClick("Upcoming")}
        >
          Upcoming
        </Button>
      </div>
      <Row className="mt-4 pt-3">
        {data.map((value, index) => (
          <Col lg={3} key={index}>
            <Card
              bodyStyle={{ padding: 0 }}
              style={{ borderRadius: "10px", overflow: "hidden" }}
            >
              <div
                style={{
                  background: "#F1F1F1",
                  borderRadius: "10px 10px 0px 0px",
                }}
                className="p-5 text-center"
              >
                <Image
                  style={{ height: "97.5px", width: "97.5px" }}
                  src={value.img}
                  alt="clock"
                />
              </div>
              <div className="p-3">
                <Title
                  level={4}
                  style={{ color: "#11142D !important", marginBottom: 0 }}
                  className="ff-gotham-normal fs_16"
                >
                  {value.title}
                </Title>
                <div className="mt-3 d-flex align-items-center gap-5">
                  <div className="d-flex align-items-center gap-2">
                    <div
                      style={{ marginTop: "1px" }}
                      className="blue-dot"
                    ></div>
                    <Text
                      style={{
                        color: "#808191",
                      }}
                      className="fs_12 mb-0 ff-inter fw-medium"
                    >
                      {value.time}
                    </Text>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <div
                      style={{ marginTop: "1px" }}
                      className="green-dot"
                    ></div>
                    <Text
                      style={{
                        color: "#808191",
                      }}
                      className="fs_12 ff-inter fw-medium"
                    >
                      {value.date}
                    </Text>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2 mt-3">
                  <div className="name-bg d-flex align-items-center justify-content-center">
                    <p className="mb-0 fw-bold fs_12 ff-gotham-normal">M</p>
                  </div>
                  <p className="mb-0 ff-gotham-bold fs_12">Moni Roy</p>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Sessions;
