import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

export const raiseCounselingTicket = async (data) => {
  const res = await axios({
    method: "post",
    url: `${API_URL}/create-counseling-ticket`,
    data: data,
    headers: {
      token: token,
    },
  });
  console.log(token);
  return res?.data;
};
