import React, { useState } from "react";
import "./CounselorChat.css";
import { Layout, Input, Avatar, List } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import pin from "../../../assets/image/svg/sharePin.svg";
import sendMessage from "../../../assets/image/svg/sendMessage.svg";
import emojiOpener from "../../../assets/image/svg/emojiOpener.svg";
import Picker from "emoji-picker-react";

const { Sider, Content } = Layout;

const CounselorChat = () => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleEmojiOpenerClick = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleCloseEmojiPicker = () => {
    setShowEmojiPicker(false);
  };

  const onEmojiClick = (emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    console.log("file", files);
  };

  const handlePinClick = () => {
    document.getElementById("mediaInput").click();
  };

  const contacts = [
    { name: "Kalyuzhnaya Valeria", time: "12:35", message: "👋" },
    { name: "Natalia Zhadanova", time: "11:52", message: "Ok" },
    { name: "Fomenko Anastasia", time: "11:31", message: "💬" },
    { name: "Petrichenko Sofia", time: "11:00", message: "Company ..." },
    {
      name: "Svetlana Galkina",
      time: "11:09",
      message: "The password is not c...",
    },
    { name: "Natalya", time: "09:16", message: "Already started," },
    { name: "Fakhrutdinova Guli...", time: "09:12", message: "a" },
    { name: "Fakhrutdinova Guli...", time: "09:12", message: "a" },
    { name: "Fakhrutdinova Guli...", time: "09:12", message: "a" },
    { name: "Fakhrutdinova Guli...", time: "09:12", message: "a" },
    { name: "Fakhrutdinova Guli...", time: "09:12", message: "a" },
  ];

  return (
    <div className="py-4">
      <h4 className="ff-gotham-bold fs_25 mb-3">Recent chats</h4>
      <Layout className="chat-layout">
        <Sider width={300} className="chat-sider">
          <div className="search-bar">
            <Input
              placeholder="Search by chats and people"
              prefix={<SearchOutlined />}
            />
          </div>
          <List
            style={{ height: "65vh" }}
            itemLayout="horizontal"
            className="px-3 overflow-auto"
            dataSource={contacts}
            renderItem={(item) => (
              <List.Item>
                <div className="ff-gotham-normal d-flex gap-2 align-items-center">
                  <div>{<Avatar icon={<UserOutlined />} />}</div>
                  <div>
                    <p style={{ color: "#191B1D" }} className="mb-0">
                      {item.name}
                    </p>
                    <p
                      style={{ color: "#A0A7AF", opacity: 1 }}
                      className="fs_12 mb-0"
                    >
                      {item.message}
                    </p>
                  </div>
                </div>
                <div className="">
                  <p
                    style={{ color: "#A0A7AF" }}
                    className="mb-0 ff-roboto fs_14"
                  >
                    {item.time}
                  </p>
                  <div
                    style={{
                      width: "16px",
                      height: "16px",
                      borderRadius: "100%",
                    }}
                    className="bg_theme ms-auto d-flex align-items-center justify-content-center mt-1"
                  >
                    <p
                      style={{ lineHeight: "20px", letterSpacing: "0.15px" }}
                      className="mb-0 fs_10 text-white ff-roboto"
                    >
                      1
                    </p>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </Sider>
        <Content className="chat-content">
          <div className="chat-header d-flex align-items-center gap-2">
            <Avatar icon={<UserOutlined />} />
            <div>
              <h2
                style={{ letterSpacing: "0.15px" }}
                className="ff-gotham-bold fs_16 mb-0"
              >
                Petrichenko Sofia
              </h2>
              <p
                style={{ letterSpacing: "0.15px", color: "#A0A7AF" }}
                className="mb-0 ff-gotham-normal fs_14"
              >
                Click Here for contact in
              </p>
            </div>
          </div>
          <div style={{ height: "50vh" }} className="chat-messages">
            <div className="message p-4 ms-auto">
              <p style={{ color: "#191B1D" }} className="ff-gotham-normal mb-2">
                selling their services dearly using my Author's technology.
              </p>
              <p style={{ color: "#191B1D" }} className="ff-gotham-normal mb-2">
                On the 4th day: I'll tell you how cheap it is to attract
                subscribers to a bot or an autowork and not drain the budget.
              </p>
              <p style={{ color: "#191B1D" }} className="ff-gotham-normal mb-2">
                Day 5: I will reveal the chips for working with the subscriber
                base and how to receive applications and sales without investing
                in advertising.
              </p>
              <p style={{ color: "#191B1D" }} className="ff-gotham-normal mb-2">
                You now know the Training program. Now do your homework in your
                personal account. There will be no results without it - you will
                just merge time.
              </p>
              <p style={{ color: "#191B1D" }} className="ff-gotham-normal mb-2">
                Only full return: do the DZ + listen carefully to the training -
                it will allow you to squeeze the maximum!
              </p>
              <p style={{ color: "#191B1D" }} className="ff-gotham-normal mb-2">
                Only full return: do the DZ + listen carefully to the training -
                it will allow you to squeeze the maximum!
              </p>
              <p style={{ color: "#191B1D" }} className="ff-gotham-normal mb-2">
                Only full return: do the DZ + listen carefully to the training -
                it will allow you to squeeze the maximum!
              </p>
              <p className="ff-gotham-normal color_theme">
                <a className="color_theme" href="#">
                  https://lalalalalalalalala/GyuYhuji/control
                </a>
              </p>
            </div>
            <div className="response p-4 me-auto">
              <div className="d-flex align-items-center justify-content-between">
                <p className="ff-gotham-bold fs_14 color_theme mb-0">
                  Natalia Zhadanova
                </p>
                <p
                  style={{ color: "#A0A7AF" }}
                  className="mb-0 ff-gotham-normal mb-0"
                >
                  11:52
                </p>
              </div>
              <p
                style={{ color: "#191B1D" }}
                className="mb-0 ff-roboto fs_14 mt-2"
              >
                will there be recordings of webinars?
              </p>
            </div>
            <div className="response p-4 me-auto">
              <div className="d-flex align-items-center justify-content-between">
                <p className="ff-gotham-bold fs_14 color_theme mb-0">
                  Natalia Zhadanova
                </p>
                <p
                  style={{ color: "#A0A7AF" }}
                  className="mb-0 ff-gotham-normal mb-0"
                >
                  11:52
                </p>
              </div>
              <p
                style={{ color: "#191B1D" }}
                className="mb-0 ff-roboto fs_14 mt-2"
              >
                will there be recordings of webinars?
              </p>
            </div>
          </div>
          <div
            style={{ borderBottomRightRadius: "20px" }}
            className="chat-input d-flex align-items-center gap-2 position-relative"
          >
            <img
              className="cursor-pointer"
              style={{ width: "24px", height: "24px" }}
              src={pin}
              alt="pin"
              onClick={handlePinClick}
            />
            <input
              id="mediaInput"
              type="file"
              multiple
              accept="image/*,video/*"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <div className="input-div d-flex align-items-center gap-3 w-100 position-relative">
              <Input
                style={{ color: "#BFC6CD" }}
                className="border-0 w-100 message-input ff-roboto fs_16"
                placeholder="Write a message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <img
                className="cursor-pointer"
                style={{ width: "20px", height: "20px" }}
                src={sendMessage}
                alt="sendMessage"
              />
              <img
                className="cursor-pointer"
                style={{ width: "24px", height: "24px" }}
                src={emojiOpener}
                alt="emojiOpener"
                onClick={handleEmojiOpenerClick}
              />
              {showEmojiPicker && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 1000,
                    bottom: "60px",
                    right: "10px", // Adjust as needed
                    padding: "10px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <div className="d-flex justify-content-end">
                    <span
                      className="cursor-pointer"
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        marginBottom: "10px",
                        color: "#0000009c", // Red color for the close button
                      }}
                      onClick={handleCloseEmojiPicker}
                    >
                      X
                    </span>
                  </div>
                  <Picker onEmojiClick={onEmojiClick} />
                </div>
              )}
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default CounselorChat;
