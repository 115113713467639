import React, { useState } from "react";
import "./assesment.css";

const AssesmentQuestions = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [sliderValue, setSliderValue] = useState(50); // Initial value for the slider

  const questions = [
    {
      title:
        "Do you think regular mental wellness practices can enhance an athlete's overall performance?",
      description:
        "Regular mental wellness practices can significantly enhance an athlete's overall performance, resilience, and ability to recover from setbacks.",
    },
    {
      title:
        "Do you believe that mental training is as important as physical training for athletic success?",
      description:
        "Mental training is essential for achieving peak performance, improving focus, and handling competition pressure eff_gothamectively.",
    },
  ];

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSliderValue(50);
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setSliderValue(50);
    }
  };

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
  };

  return (
    <div className="my-4">
      <h4 className="ff-gotham-bold fs_25 mb-0">Assesment Tests</h4>
      <div className="card mt-4 border-0" style={{ height: "100%" }}>
        <div className="p-5">
          <h4
            style={{ maxWidth: "776px" }}
            className="text-center ff-gotham-bold fs_28 mb-0 color_black4 mx-auto"
          >
            {questions[currentQuestion].title}
          </h4>
          <p
            style={{ maxWidth: "938px" }}
            className="color_gray fs_15 ff-gotham-normal mt-3 mb-0 text-center mx-auto"
          >
            {questions[currentQuestion].description}
          </p>
          <div className="py-5">
            <input
              type="range"
              min="0"
              max="100"
              value={sliderValue}
              onChange={handleSliderChange}
              className="slider w-100"
              style={{
                width: "100%",
                height: "20px",
                background: "#009345",
                borderRadius: "40px",
                outline: "none",
                transition: "opacity .15s ease-in-out",
              }}
            />
            <div className="d-flex align-items-center justify-content-between mt-4">
              <p className="fs_15 color_gray_2 ff-gotham-normal">
                Totally disagree
              </p>
              <p className="fs_15 color_gray_2 ff-gotham-normal">
                Totally agree
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5 align-items-center gap-3">
            <button
              style={{ letterSpacing: "0.50px" }}
              onClick={handleBack}
              className="px-5 py-2 bg-transparent rounded-3 fs_13 color_theme border-1 light_blue border-success border ff-gotham-bold"
            >
              Back
            </button>
            <button
              style={{ letterSpacing: "0.50px" }}
              onClick={handleNext}
              className="px-5 py-2 bg_theme rounded-3 border border-1 border-transparent fs_13 text-white ff-gotham-bold"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssesmentQuestions;
