import React from "react";
import "./AboutUs.css";
import nextArrow from "../../../assets/image/png/nextArrow.png";
import mainImg from "../../../assets/image/png/girlWithRacket.png";
import { Button, Col, Container, Form, FormGroup, Row } from "react-bootstrap";

const AboutUs = () => {
  return (
    <div id="aboutus" className="py-5">
      <Container>
        <Row className="align-items-center flex-column-reverse flex-lg-row">
          <Col className="mt-3" lg={6}>
            <div>
              <div className="d-flex align-items-center gap-4">
                <div className="hr-line"></div>
                <div className="bg-white px-3 py-1 rounded-2">
                  <p className="mb-0 ff-gotham-normal fs_20">About Us</p>
                </div>
              </div>
              <h2 className="mb-0 ff-gotham-bold fs-56 mt-4">
                Get to Know Us <span className="elevate-text">Better</span>
              </h2>
              <p
                style={{ opacity: "60%" }}
                className="mb-0 mt-4 ff-gotham-normal fs_16 clr-black"
              >
                At Inspire Mental Fitness, we are dedicated to empowering
                athletes by providing expert mental wellness resources and
                personalized support. Our mission is to help you achieve peak
                performance, resilience, and overall well-being, both on and off
                the field. Join us to unlock your full potential!
              </p>

              <Button
                style={{ width: "150px", height: "45px", borderRadius: "10px" }}
                className="btn-green-common d-flex align-items-center mt-4 gap-2 justify-content-center"
              >
                Read More
                <img
                  style={{ width: "13px", height: "10px" }}
                  src={nextArrow}
                  alt="nextArrow"
                />
              </Button>
            </div>
          </Col>
          <Col lg={6}>
            <div className="text-center position-relative">
              <img className="w-75" src={mainImg} alt="boyWithFootball" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
