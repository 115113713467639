import React, { useState } from "react";
import "./Features.css";
import { Col, Container, Row } from "react-bootstrap";
import pieChart from "../../../assets/image/png/pieChart.png";
import squareBoxes from "../../../assets/image/png/squareBoxes.png";
import tickWithGreenBg from "../../../assets/image/png/tickWithGreenBg.png";

const Features = () => {
  const [data, setData] = useState([
    {
      img: pieChart,
      head: "Personalized Mental Training Plans",
      para: "Receive customized mental training programs designed to suit your specific sport and individual needs. Improve and boost your confidence with our guidance.",
    },
    {
      img: squareBoxes,
      head: "Interactive Counseling Sessions",
      para: "Access one-on-one virtual counseling with licensed sports psychologists. Get the support you need to overcome mental hurdles and enhance your performance.",
    },
    {
      img: tickWithGreenBg,
      head: "Mental Wellness Resources Library",
      para: "Explore a vast collection of articles, videos, and tools focused on mental health and sport psychology. Stay informed and motivated with the latest research and insights.",
    },
  ]);
  return (
    <div id="Features" className="bg-white py-5">
      <Container>
        <div className="d-flex align-items-center gap-4 justify-content-center">
          <hr className="horizontal-line" />
          <h3 className="ff-gotham-normal clr-black fs_20">Features</h3>
          <hr className="horizontal-line" />
        </div>
        <h2
          style={{ maxWidth: "600px" }}
          className="mb-0 ff-gotham-bold fs-56 text-center mx-auto mt-3"
        >
          <span className="elevate-text">Tailored</span> Support for Every
          Athlete
        </h2>
        <p
          style={{ maxWidth: "500px" }}
          className="mb-0 fs_16 clr-black opacity-60 ff-gotham-normal mt-4 mx-auto text-center"
        >
          Our platform offers specialized features to enhance your mental
          wellness, ensuring you stay at the top of your game.
        </p>
        <Row className="mt-3">
          {data.map((value, index) => {
            return (
              <Col lg={4} sm={6} key={index} className="mt-4">
                <div className="feature-card cursor-pointer p-4 h-100 d-flex justify-content-between flex-column">
                  <div>
                    <div className="card-image-circle d-flex align-items-center justify-content-center">
                      <img className="card-image" src={value.img} alt="" />
                    </div>
                    <h4 className="ff-gotham-bold fs-30 mt-3">{value.head}</h4>
                  </div>
                  <p className="ff-gotham-normal fs-17 mb-0 mt-3">
                    {value.para}
                  </p>
                </div>
              </Col>
            );
          })}
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
};

export default Features;
