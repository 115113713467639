import React, { useState, useRef, useEffect } from "react";
import CalendarDatePicker from "./CalendarDatePicker";
import { Modal } from "react-bootstrap";
import { FaEdit, FaRegEdit, FaUser, FaUserEdit } from "react-icons/fa";
import editIcon from "../../../assets/image/png/Edit.png";
import usersIcon from "../../../assets/image/png/usersicon.png";
import { TimePicker } from "antd";

export default function CounselorCalendarModal({
  isOpen = false,
  toggle,
  onSubmit,
  submitText = "Save",
  calendars = [],
  attendees = [],
  schedule,
  startDate,
  endDate,
  body = "",
}) {
  const [openSelectCalendars, setOpenSelectCalendars] = useState(false);
  const [openSelectAttendees, setOpenSelectAttendees] = useState(false);
  const wrapperSelectCalendarsRef = useRef(null);
  const wrapperSelectAttendeesRef = useRef(null);
  const dateRangePickerRef = useRef(null);
  const subjectRef = useRef(null);
  const noteRef = useRef(null);

  const [calendarId, setCalendarId] = useState(calendars[0]?.id || "");
  const [attendeeId, setAttendeeId] = useState(attendees[0]?.id || "");
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [title, setTitle] = useState("");
  const [note, setNote] = useState(body || ""); // Initialize note with body
  const [startDateTime, setStartDateTime] = useState(new Date()); // Default to today's date and time
  const [endDateTime, setEndDateTime] = useState(new Date()); // Default to today's date and time

  const handleClick = (e) => {
    if (wrapperSelectCalendarsRef.current?.contains(e.target)) return;
    if (wrapperSelectAttendeesRef.current?.contains(e.target)) return;
    setOpenSelectCalendars(false);
    setOpenSelectAttendees(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, false);
    return () => {
      document.removeEventListener("click", handleClick, false);
    };
  }, []);
  console.log("attendees", attendees);

  useEffect(() => {
    if (dateRangePickerRef.current) {
      if (schedule) {
        setCalendarId(schedule.calendarId);
        const selectedAttendee = attendees.find((element) =>
          schedule.attendees.includes(element.name)
        );
        setAttendeeId(selectedAttendee ? selectedAttendee.id : "");
        setTitle(schedule.title);
        setNote(schedule.body || "");

        const scheduleStart = new Date(schedule.start);
        const scheduleEnd = new Date(schedule.end);

        if (isNaN(scheduleStart.getTime()) || isNaN(scheduleEnd.getTime())) {
          console.error(
            "Invalid schedule dates:",
            schedule.start,
            schedule.end
          );
        } else {
          setStartDateTime(scheduleStart);
          setEndDateTime(scheduleEnd);
          dateRangePickerRef.current.setStartDate(
            scheduleStart.toISOString().split("T")[0]
          );
        }
      } else {
        const today = new Date();
        setStartDateTime(startDate ? new Date(startDate) : today);
        setEndDateTime(endDate ? new Date(endDate) : today);
        dateRangePickerRef.current.setStartDate(
          startDate
            ? new Date(startDate).toISOString().split("T")[0]
            : today.toISOString().split("T")[0]
        );
      }
    }
  }, [schedule, startDate, endDate, attendees]);

  function reset() {
    const today = new Date();
    setCalendarId(calendars[0]?.id || "");
    setAttendeeId(attendees[0]?.id || "");
    setTitle("");
    setNote(body || "");
    setStartDateTime(today);
    setEndDateTime(today);
    if (dateRangePickerRef.current) {
      dateRangePickerRef.current.setStartDate(
        today.toISOString().split("T")[0]
      );
    }
  }

  const handleDateChange = (dates) => {
    const [start] = dates;
    const startDate = new Date(start);
    if (!(startDate instanceof Date) || isNaN(startDate.getTime())) {
      console.error("Invalid start date:", startDate);
      return;
    }

    setStartDateTime(startDate);
    setEndDateTime(startDate);
  };

  const formatTimeToInput = (date) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      console.error("Invalid date:", date);
      return "00:00";
    }

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const handleTimeChange = (event, isStart) => {
    const [hours, minutes] = event.target.value.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes)) {
      console.error("Invalid time format:", event.target.value);
      return;
    }

    const date = isStart ? new Date(startDateTime) : new Date(endDateTime);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);

    if (isStart) {
      setStartDateTime(date);
    } else {
      setEndDateTime(date);
    }
  };

  // const handleAttendeeSelect = (attendee) => {
  //   if (!selectedAttendees.some((a) => a.id === attendee.id)) {
  //     setSelectedAttendees([...selectedAttendees, attendee]);
  //   }
  //   setOpenSelectAttendees(false);
  // };

  const handleAttendeeSelect = (attendee) => {
    if (editingIndex !== null) {
      const updatedAttendees = [...selectedAttendees];
      updatedAttendees[editingIndex] = attendee;
      setSelectedAttendees(updatedAttendees);
      setEditingIndex(null);
    } else if (!selectedAttendees.some((a) => a.id === attendee.id)) {
      setSelectedAttendees([...selectedAttendees, attendee]);
    }
    setOpenSelectAttendees(false);
  };

  const resetInputs = () => {
    const today = new Date();
    setCalendarId(calendars[0]?.id || "");
    setSelectedAttendees([]);
    setTitle("");
    setNote("");
    setStartDateTime(today);
    setEndDateTime(today);
    if (dateRangePickerRef.current) {
      dateRangePickerRef.current.setStartDate(
        today.toISOString().split("T")[0]
      );
    }
  };

  const handleAttendeeRemove = (indexToRemove) => {
    setSelectedAttendees((prevSelectedAttendees) =>
      prevSelectedAttendees.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <Modal
      show={isOpen}
      onHide={() => {
        toggle();
        reset();
      }}
      centered
    >
      <div className="tui-full-calendar-popup-container">
        {/* Title */}
        <div className="tui-full-calendar-popup-section pt-4">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            className="form-control custom-title-text"
            placeholder="Event title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        {/* Date */}
        <div className="d-flex align-items-center gap-2 mt-4 justify-content-between">
          <div className="tui-full-calendar-popup-section">
            <label htmlFor="date-range">Date</label>
            <CalendarDatePicker
              ref={dateRangePickerRef}
              start={startDateTime}
              end={endDateTime}
              onChange={handleDateChange}
            />
          </div>
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div className="tui-full-calendar-popup-section">
              <label htmlFor="start-time">Start Time</label>
              <input
                type="time"
                id="start-time"
                className="form-control"
                value={formatTimeToInput(startDateTime)}
                onChange={(e) => handleTimeChange(e, true)}
              />
            </div>
            <div className="tui-full-calendar-popup-section">
              <label htmlFor="end-time">End Time</label>
              <input
                type="time"
                id="end-time"
                className="form-control"
                value={formatTimeToInput(endDateTime)}
                onChange={(e) => handleTimeChange(e, false)}
              />
            </div>
          </div>
        </div>
        {/* Attendees */}
        <div
          ref={wrapperSelectAttendeesRef}
          className="tui-full-calendar-popup-section d-flex position-relative attendees gap-1 align-items-center w-100 ps-2 mt-4"
          style={{ position: "relative" }}
          onClick={() => setOpenSelectAttendees(!openSelectAttendees)}
        >
          <img src={usersIcon} style={{ cursor: "pointer", width: "30px" }} />
          <div className="ms-2 d-flex align-items-center gap-2">
            {selectedAttendees.map((attendee, index) => (
              <div
                key={index}
                style={{
                  border: "0.8px solid #0000004a",
                  borderRadius: "4px",
                  padding: "3px 6px",
                  cursor: "pointer",
                }}
                className="d-flex align-items-center gap-1 position-relative"
                onClick={() => handleAttendeeRemove(index)}
              >
                <img
                  className="me-1 rounded-1"
                  style={{ width: "18px", height: "18px" }}
                  src={attendee.attendeeImage}
                  alt={`Attendee ${index}`}
                />
                <div>
                  <p style={{ fontSize: "10px" }} className="mb-0">
                    {attendee.name} ({attendee.role})
                  </p>
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the remove action when editing
                    setEditingIndex(index);
                    setOpenSelectAttendees(true);
                  }}
                  style={{
                    top: "-36%",
                    right: "-8%",
                    borderRadius: "100%",
                    width: "14px",
                    height: "14px",
                  }}
                  className="bg-white cursor-pointer p-2 position-absolute d-flex align-items-center justify-content-center"
                >
                  <img
                    className=""
                    src={editIcon}
                    style={{
                      width: "8px",
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          {openSelectAttendees && (
            <div className="attendee-select-dropdown position-absolute">
              {attendees
                .filter(
                  (attendee) =>
                    !selectedAttendees.some((a) => a.id === attendee.id)
                )
                .map((attendee) => (
                  <div
                    key={attendee.id}
                    className="attendee-select-item mt-1 d-flex align-items-center"
                    onClick={() => handleAttendeeSelect(attendee)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      className="me-2 rounded-1"
                      style={{ width: "18px", height: "18px" }}
                      src={attendee?.attendeeImage}
                      alt=""
                    />
                    <p style={{ fontSize: "10px" }} className="mb-0">
                      {attendee.name} ({attendee.role})
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
        {/* Note */}
        <div className="tui-full-calendar-popup-section mt-4">
          <label htmlFor="note">Note</label>
          <textarea
            id="note"
            className="form-control"
            placeholder="Event notes"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        {/* Buttons */}
        <div className="tui-full-calendar-popup-footer mt-4 d-flex align-items-center justify-content-end gap-2">
          <button
            style={{ height: "42px" }}
            className="bg_theme border-0 px-4 rounded-2 text-white"
            onClick={() => {
              onSubmit({
                calendarId,
                attendees: selectedAttendees.map(
                  (attendee) => attendee.attendeeImage
                ),

                title,
                body: note,
                start: startDateTime.toISOString(),
                end: endDateTime.toISOString(),
              });
              toggle();
              resetInputs();
            }}
          >
            {submitText}
          </button>
          <button
            style={{ background: "#0071BD", height: "42px" }}
            className="border-0 px-4 rounded-2 text-white"
            onClick={() => {
              toggle();
              resetInputs();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}
