import { Layout } from "antd";
import "./GenerateLink.css";
import React, { useState } from "react";
import { Button, Col, Row, Toast } from "react-bootstrap";
import callIcon from "../../../../assets/image/svg/callIcon.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { raiseCounselingTicket } from "../../../../services/CounselingTicketRaising";
import checked from "../../../../assets/image/png/checked.png";

const GenerateLink = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [isTimeConfirmed, setIsTimeConfirmed] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
  };

  const allTimeSlots = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];

  // Function to check if a time slot should be disabled
  const isTimeDisabled = (time) => {
    const currentDate = new Date();

    // If the selected date is today, disable times in the past
    if (selectedDate.toDateString() === currentDate.toDateString()) {
      const [hour, minute] = time.split(":").map(Number);
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();

      // Disable time slots that are before the current time
      return (
        hour < currentHour || (hour === currentHour && minute <= currentMinute)
      );
    }

    // For future dates, no time slot should be disabled
    return false;
  };

  const handleTimeSelect = (time) => {
    if (!isTimeDisabled(time)) {
      setSelectedTime(time);
      setIsTimeConfirmed(false); // Reset confirmation until user presses "Confirm"
    }
  };

  const handleConfirmTime = () => {
    // Format the date to "YYYY-MM-DD"
    const formattedDate = selectedDate.toISOString().split("T")[0];

    const sessionData = {
      date: formattedDate,
      time: selectedTime,
    };

    console.log("Session Data:", sessionData);

    raiseCounselingTicket(sessionData)
      .then((response) => {
        console.log("Ticket raised successfully:", response);
        setIsTimeConfirmed(true);
        setShowToast(true); // Show toast when session is confirmed
      })
      .catch((error) => {
        console.error("Error raising the ticket:", error);
      });
  };

  return (
    <>
      <div style={{ background: "#F4F8FA" }} className="">
        <h4 className="fs_25 fw-bold ff-gotham-bold mb-4">Session</h4>
        <Row>
          <Col className="" lg={9}>
            <Layout
              style={{ background: "#ECEEEF" }}
              className="p-4 generate-link-css h-100 d-flex flex-column justify-content-between"
            >
              <div className="">
                <h4 className="fs_18 fw-bold ff-gotham-bold">
                  Set Session Time
                </h4>
                <Row className="mt-3">
                  <Col lg={6}>
                    <div className="d-flex flex-column">
                      <label className="color_gray_2 ff-gotham-normal fs_16">
                        Select Date
                      </label>
                      <div className="calendar-wrapper mt-2">
                        <Calendar
                          onChange={handleDateChange}
                          value={selectedDate}
                          className="react-calendar w-100"
                          minDate={new Date()} // Prevent selection of past dates
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="d-flex flex-column">
                      <label className="color_gray_2 ff-gotham-normal fs_16">
                        Select Time
                      </label>
                      <div className="time-slots mt-2">
                        <div className="d-flex flex-wrap">
                          {allTimeSlots.map((time, index) => (
                            <Button
                              key={index}
                              onClick={() => handleTimeSelect(time)}
                              className={`me-2 mb-2 rounded-2 select-time-box`}
                              style={{
                                backgroundColor:
                                  selectedTime === time
                                    ? "#009345"
                                    : "transparent",
                                color:
                                  selectedTime === time ? "#fff" : "#808191",
                                borderColor: "#009345",
                                cursor: isTimeDisabled(time)
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                              disabled={isTimeDisabled(time)} // Disable past times
                            >
                              {time}
                            </Button>
                          ))}
                        </div>

                        <div className="text-center">
                          {selectedTime && !isTimeConfirmed && (
                            <Button
                              onClick={handleConfirmTime}
                              className="mt-3 w-50 mx-auto text-white rounded-2 py-2"
                              style={{
                                backgroundColor: "#009345",
                                borderColor: "#009345",
                              }}
                            >
                              Confirm Session
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Layout>
          </Col>

          <Col lg={3}>
            <div className="call-test-bg px-4 pt-4 pb-3 bg_theme">
              <div className="d-flex align-items-center justify-content-center">
                <div
                  style={{ width: "57px", height: "57px" }}
                  className="bg_theme d-flex align-items-center justify-content-center call-icon-bg"
                >
                  <img
                    style={{ width: "36px", height: "20px" }}
                    src={callIcon}
                    alt="callicon"
                  />
                </div>
              </div>
              <p
                style={{ lineHeight: "11.48px" }}
                className="text-center text-white mb-0 mt-3 ff-gotham-normal fs_12"
              >
                Test a call to check settings <br /> and option.
              </p>
              <Button
                style={{ lineHeight: "14.36px" }}
                className="bg-white border-0 rounded-2 py-3 color_theme w-100 mt-4 ff-gotham-normal fs_15"
              >
                Pre Call test
              </Button>
            </div>
          </Col>
        </Row>

        {/* Toast for Session Confirmation */}
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1050,
            backgroundColor: "#28a745",
            width: "300px",
            height: "300px",
            borderRadius: "8px",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="d-flex align-items-center justify-content-center flex-column gap-3">
            <div>
              <img
                style={{ width: "45px", height: "45px" }}
                src={checked}
                alt=""
              />
            </div>
            <div className="text-center">
              <span
                className=" ff-gotham-normal"
                style={{
                  fontSize: "16px",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                Your session is confirmed on {selectedDate.toDateString()} at{" "}
                {selectedTime}.
              </span>
            </div>
          </div>
        </Toast>
      </div>
    </>
  );
};

export default GenerateLink;
