import React, { useEffect, useState } from "react";
import "./Appnav.css";
import { Button, Container, ListGroup } from "react-bootstrap";
import logo from "../../../assets/image/png/logo.png";
import nextArrow from "../../../assets/image/png/nextArrow.png";
import arrowRight from "../../../assets/image/svg/arrowRight.svg";
import { List, Row } from "antd";
import { useNavigate } from "react-router-dom";

const AppNav = () => {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userInitial, setUserInitial] = useState("");
  const token = localStorage.getItem("token");

  const handleNavigate = () => {
    const userData = localStorage.getItem("userData");
    if (token) {
      try {
        const parsedData = JSON.parse(userData);
        const userRole = parsedData.role;
        if (token && userRole === "Coach") {
          navigate("/coaching-dashboard");
        }
        if (token && userRole === "Athlete") {
          navigate("/fieldhouse-dashboard");
        }
        if (token && userRole === "Counselor") {
          navigate("/counselor-portal");
        }
      } catch (error) {}
    } else {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      try {
        const parsedData = JSON.parse(userData);
        setUserName(parsedData.name);
        setUserRole(parsedData.role);
        setUserInitial(parsedData.name.charAt(0));
      } catch (error) {
        console.error("Error parsing userData:", error);
      }
    }
  }, []);

  return (
    <div className="pt-4">
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <img
              className="position-relative logo-index"
              style={{ width: "180px", height: "55px" }}
              src={logo}
              alt="logo"
            />
          </div>
          <div>
            <ListGroup
              className={`list-unstyled gap-4 flex-lg-row d-flex justify-content-center align-items-center ${
                showNav === true ? "nav-show" : "nav-hidden"
              }`}
            >
              <List.Item className="ff-gotham-normal fs_16">
                <a className="color_black4" href="#Features">
                  Features
                </a>
              </List.Item>
              <List.Item className="ff-gotham-normal fs_16">
                <a className="color_black4" href={"#aboutus"}>
                  About Us
                </a>
              </List.Item>
              <List.Item className="ff-gotham-normal fs_16">
                <a className="color_black4" href="#Pricing">
                  Pricing
                </a>
              </List.Item>
              <List.Item className="ff-gotham-normal fs_16">
                <a className="color_black4" href="#Blog">
                  Blog
                </a>
              </List.Item>
              <List.Item className="ff-gotham-normal fs_16">
                <a className="color_black4" href="#">
                  Contact Us
                </a>
              </List.Item>
              <List.Item className="d-sm-none">
                <Button
                  onClick={handleNavigate}
                  className="bg-transparent border-0 clr-black ff-gotham-bold fs_16"
                >
                  Login
                </Button>
              </List.Item>
              <List.Item className="d-sm-none">
                <Button
                  onClick={() => navigate("/choose-role")}
                  className="btn-green-common d-flex align-items-center gap-2 px-3 ff-gotham-bold fs_16"
                >
                  Register Now
                  <img
                    style={{ width: "13px", height: "10px" }}
                    src={nextArrow}
                    alt="nextArrow"
                  />
                </Button>
              </List.Item>
            </ListGroup>
          </div>
          <Row className="gap-2 align-items-center">
            {token ? (
              <div
                onClick={handleNavigate}
                className="d-flex align-items-center justify-content-center gap-3 py-3 cursor-pointer"
              >
                <div
                  style={{
                    width: "44px",
                    height: "44px",
                    borderRadius: "100%",
                    backgroundColor: " #00000033",
                  }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <p
                    style={{ color: "#404040" }}
                    className="mb-0 ff-gotham-bold fs_18"
                  >
                    {userInitial}
                  </p>
                </div>
                <div
                  className={` align-items-center gap-3 position-relative d-flex`}
                >
                  <div>
                    <p className="mb-0 ff-gotham-bold">{userName}</p>
                    <p className="mb-0 ff-gotham-normal">{userRole}</p>
                  </div>
                  {/* <div>
                    <div className="arrow-circle d-flex align-items-center justify-content-center">
                      <img src={arrowRight} alt="" />
                    </div>
                  </div> */}
                </div>
              </div>
            ) : (
              <>
                <Button
                  onClick={() => navigate("/sign-in")}
                  className="bg-transparent border-0 clr-black ff-gotham-bold d-none d-sm-block fs_16"
                >
                  Login
                </Button>
                <Button
                  onClick={() => navigate("/choose-role")}
                  className="btn-green-common align-items-center gap-2 px-3 ff-gotham-bold d-none d-sm-flex fs_16"
                >
                  Register Now
                  <img
                    style={{ width: "13px", height: "10px" }}
                    src={nextArrow}
                    alt="nextArrow"
                  />
                </Button>
                <Button
                  onClick={() => setShowNav(!showNav)}
                  className={`d-flex flex-column bg-transparent border-0 ${
                    showNav === true ? "hamburger-2" : "hamburger"
                  }`}
                >
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </Button>
              </>
            )}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default AppNav;
