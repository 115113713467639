import React from "react";
import "../components/landingPage/landingpage.css";
import AppNav from "../components/landingPage/AppNavbar/AppNav";
import AppHero from "../components/landingPage/AppHeroSec/AppHero";
import TrustedByCoaches from "../components/landingPage/TrustedByCoaches/TrustedByCoaches";
import Features from "../components/landingPage/Features/Features";
import AboutUs from "../components/landingPage/AboutUs/AboutUs";
import WhatTheyAreSaying from "../components/landingPage/WhatTheyAreSaying/WhatTheyAreSaying";
import Blogs from "../components/landingPage/Blogs/Blogs";
import Pricing from "../components/landingPage/Pricing/Pricing";
import TryInspire from "../components/landingPage/TryInspire/TryInspire";
import AppFooter from "../components/landingPage/AppFooter/AppFooter";

import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className="d-flex flex-column landing-page-body">
      <AppNav />
      <AppHero />
      <TrustedByCoaches />
      <Features />
      <AboutUs />
      <WhatTheyAreSaying />
      <Blogs />
      <Pricing />
      <TryInspire />
      <AppFooter />
    </div>
  );
};

export default LandingPage;
