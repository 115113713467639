import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { toast } from "react-toastify";
import {
  userSignupSuccess,
  userSignupFailure,
  setRedirectPath as setUserRedirectPath,
} from "../slice/UserSlice";
import {
  userLoginSuccess,
  userLoginFailure,
  setRedirectPath as setUserLoginRedirectPath,
} from "../slice/UserLoginSlice";
import {
  otpVerificationSuccess,
  otpVerificationFailure,
  setRedirectPath as setOtpRedirectPath,
} from "../slice/OtpVerificationSlice";

const API_URL = process.env.REACT_APP_API_URL;

function* userSignupSaga(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/usersignup`,
      action.payload
    );
    const { data, token } = response.data;

    if (response.data.status === "success") {
      localStorage.setItem("token", token);
      localStorage.setItem("userData", JSON.stringify(data));

      yield put(userSignupSuccess(data));
      const redirectPath =
        data.role === "Athlete"
          ? "/fieldhouse-dashboard"
          : "/coaching-dashboard";
      yield put(setUserRedirectPath(redirectPath));
      toast.success("Signup successful!");
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    yield put(userSignupFailure(error.message));
  }
}

function* userLoginSaga(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/usersignin`,
      action.payload
    );
    const { data } = response;
    yield put(userLoginSuccess(data));
    if (data.status === "success") {
      const redirectPath = "/otp-verification";
      toast.success("Otp sent successfully!");
      yield put(setUserLoginRedirectPath(redirectPath));
    } else {
      console.log("data.message", data.message);
      toast.error(`${data.message}`);
    }
  } catch (error) {
    console.log("error", error);
    yield put(userLoginFailure(error.message));
  }
}

function* otpVerificationSaga(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/verifyotp`,
      action.payload
    );
    const { data, token } = response.data;

    if (response.data.status === "success") {
      yield put(otpVerificationSuccess());
      localStorage.setItem("token", token);
      localStorage.setItem("userData", JSON.stringify(data));
      const redirectPath =
        data.role === "Athlete"
          ? "/fieldhouse-dashboard"
          : data.role === "Coach"
          ? "/coaching-dashboard"
          : "/counselor-portal";
      toast.success("OTP verified successfully!");
      yield put(setOtpRedirectPath(redirectPath));
    } else {
      toast.error(response.data.message);
      yield put(otpVerificationFailure("OTP verification failed"));
    }
  } catch (error) {
    yield put(otpVerificationFailure(error.message || "An error occurred"));
  }
}

export function* watchOtpVerification() {
  yield takeLatest("otpVerification/otpVerification", otpVerificationSaga);
}

export function* watchUserSignup() {
  yield takeLatest("user/userSignup", userSignupSaga);
}

export function* watchUserLogin() {
  yield takeLatest("userLogin/userLogin", userLoginSaga);
}
