import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../../assets/image/png/logo.png";
import { List } from "antd";
import { Link } from "react-router-dom";

const AppFooter = () => {
  return (
    <div className="bg-white py-5">
      <Container>
        <Row>
          <Col lg={4} className="pe-5">
            <div>
              <img
                style={{ width: "194px", height: "59px" }}
                src={logo}
                alt="logo"
              />
              <p className="ff-gotham-normal clr-black opacity-60 fs_16 mt-3 mb-0">
                Our mission is to provide you with the tools, resources, and
                community support you need to maintain and improve your mental
                fitness.
              </p>
            </div>
          </Col>
          <Col className="mt-4 mt-lg-0" lg={8}>
            <Row>
              <Col sm={6} md={4}>
                <List>
                  <List.Item className="border-0 py-1 ff-gotham-bold fs-26 clr-black">
                    Features
                  </List.Item>
                  <List.Item className="border-0 py-1 ff-gotham-bold fs-26 clr-black">
                    About Us
                  </List.Item>
                  <List.Item className="border-0 py-1 ff-gotham-bold fs-26 clr-black">
                    Pricing
                  </List.Item>
                  <List.Item className="border-0 py-1 ff-gotham-bold fs-26 clr-black">
                    Blog
                  </List.Item>
                  <List.Item className="border-0 py-1 ff-gotham-bold fs-26 clr-black">
                    Contact Us
                  </List.Item>
                </List>
              </Col>
              <Col className="mt-4 mt-sm-0" sm={6} md={4}>
                <List>
                  <List.Item className="border-0 py-1 ff-gotham-bold fs_20 clr-black">
                    Resources
                  </List.Item>
                  <List.Item className="border-0 py-1 ff-gotham-normal fs_16 clr-black mt-2">
                    Blog
                  </List.Item>
                  <List.Item className="border-0 py-1 ff-gotham-normal fs_16 clr-black">
                    apps
                  </List.Item>
                  <List.Item className="border-0 py-1 ff-gotham-normal fs_16 clr-black">
                    Learn
                  </List.Item>
                  <List.Item className="border-0 py-1 ff-gotham-normal fs_16 clr-black">
                    Integration
                  </List.Item>
                  <List.Item className="border-0 py-1 ff-gotham-normal fs_16 clr-black">
                    Careers
                  </List.Item>
                </List>
              </Col>
              <Col className="mt-4 mt-md-0" sm={6} md={4}>
                <List>
                  <List.Item className="border-0 py-1 ff-gotham-bold fs_20 clr-black">
                    Company
                  </List.Item>
                  <List.Item className="border-0 py-1 ff-gotham-normal fs_16 clr-black mt-2">
                    Our Story
                  </List.Item>
                  <List.Item className="border-0 py-1 ff-gotham-normal fs_16 clr-black">
                    Our Team
                  </List.Item>
                  <List.Item className="border-0 py-1 ff-gotham-normal fs_16 clr-black">
                    Press
                  </List.Item>
                  <List.Item className="border-0 py-1 ff-gotham-normal fs_16 clr-black">
                    Contact Us
                  </List.Item>
                </List>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AppFooter;
