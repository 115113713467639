// import { Card, Col, Row } from "antd";
// import React, { useEffect, useState } from "react";
// import trending_image from "../../../assets/image/png/tranding_image.png";
// import { Link } from "react-router-dom";

// const CoachDashboard = () => {
//   const [userName, setUserName] = useState("");
//   useEffect(() => {
//     const userData = localStorage.getItem("userData");
//     if (userData) {
//       try {
//         const parsedData = JSON.parse(userData);
//         setUserName(parsedData.name);
//       } catch (error) {
//         console.error("Error parsing userData:", error);
//       }
//     }
//   }, []);
//   return (
//     <div className="pt-4 pb-4">
//       <h4 className=" fs_25 fw-bold ff-gotham-bold">Dashboard</h4>
//       <div className="bg_blue bg_image p-5 rounded-4 mt-3">
//         <h4 className=" fs_30 text-white ff-gotham-normal">Hi {userName}👋</h4>
//         <h2 className=" fs_50 ff-gotham-bold text-white mt-3 mb-0">
//           Welcome to Coaching Room!
//         </h2>
//         <button
//           style={{ borderRadius: "10px", height: "42px" }}
//           className="mt-5 ff-gotham-normal border-0 bg-white px-5 fs_16 py-2"
//         >
//           Book Session
//         </button>
//       </div>
//       <div className="d-flex align-items-center justify-content-between mt-4 mb-3">
//         <h4 className=" fs_25 ff-gotham-bold fw-bold color_black2">
//           Trending Jounral Topics
//         </h4>
//         <Link
//           to={"/coaching-dashboard/journal"}
//           className="ff-gotham-bold color_blue text-decoration-underline"
//         >
//           View all
//         </Link>
//       </div>
//       <Card className="rounded-5">
//         <div className="row">
//           <div className="col-md-3 col-sm-6">
//             <div className="position-relative">
//               <img
//                 className="w-100"
//                 src={trending_image}
//                 alt="trending_image"
//               />
//               <div className="position-absolute d-flex align-items-center gap-3 bottom-0 mb-2 ff-gotham-normal ps-3 text-white">
//                 <p className="mb-0">2 days ago</p>
//                 <div className="d-flex align-items-center gap-2">
//                   <div className="white-dot"></div>
//                   <p className="mb-0">7 min read</p>
//                 </div>
//               </div>
//             </div>
//             <p className="ff-gotham-normal mt-3  fs_15">
//               The Power of Visualization in<br></br> Sports
//             </p>
//           </div>
//           <div className="col-md-3 col-sm-6">
//             <div className="position-relative">
//               <img
//                 className="w-100"
//                 src={trending_image}
//                 alt="trending_image"
//               />
//               <div className="position-absolute d-flex align-items-center gap-3 bottom-0 mb-2 ff-gotham-normal ps-3 text-white">
//                 <p className="mb-0">2 days ago</p>
//                 <div className="d-flex align-items-center gap-2">
//                   <div className="white-dot"></div>
//                   <p className="mb-0">7 min read</p>
//                 </div>
//               </div>
//             </div>
//             <p className="ff-gotham-normal mt-3  fs_15">
//               Building Mental Toughness:<br></br> Strategies for Athletes
//             </p>
//           </div>
//           <div className="col-md-3 col-sm-6">
//             <div className="position-relative">
//               <img
//                 className="w-100"
//                 src={trending_image}
//                 alt="trending_image"
//               />
//               <div className="position-absolute d-flex align-items-center gap-3 bottom-0 mb-2 ff-gotham-normal ps-3 text-white">
//                 <p className="mb-0">2 days ago</p>
//                 <div className="d-flex align-items-center gap-2">
//                   <div className="white-dot"></div>
//                   <p className="mb-0">7 min read</p>
//                 </div>
//               </div>
//             </div>
//             <p className="ff-gotham-normal mt-3  fs_15">
//               Mindfulness Techniques for<br></br> Enhanced Performance
//             </p>
//           </div>
//           <div className="col-md-3 col-sm-6">
//             <div className="position-relative">
//               <img
//                 className="w-100"
//                 src={trending_image}
//                 alt="trending_image"
//               />
//               <div className="position-absolute d-flex align-items-center gap-3 bottom-0 mb-2 ff-gotham-normal ps-3 text-white">
//                 <p className="mb-0">2 days ago</p>
//                 <div className="d-flex align-items-center gap-2">
//                   <div className="white-dot"></div>
//                   <p className="mb-0">7 min read</p>
//                 </div>
//               </div>
//             </div>
//             <p className="ff-gotham-normal mt-3  fs_15">
//               Overcoming Performance Anxiety:<br></br> Tips
//             </p>
//           </div>
//         </div>
//       </Card>
//     </div>
//   );
// };

// export default CoachDashboard;

import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import trending_image from "../../../assets/image/png/tranding_image.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllJournalsRequest } from "../../../redux/slice/AllJournalsSlice";

// Format date for display
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

// Truncate title to a specified word limit
const truncateTitle = (title, wordLimit) => {
  if (!title) return "Untitled Journal";
  const words = title.split(" ");
  if (words.length <= wordLimit) return title;
  return words.slice(0, wordLimit).join(" ") + "...";
};

const CoachDashboard = () => {
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const allJournals = useSelector((state) => state.allJournals?.journals);
  const [limit, setLimit] = useState(4);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      try {
        const parsedData = JSON.parse(userData);
        setUserName(parsedData.name);
      } catch (error) {
        console.error("Error parsing userData:", error);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(fetchAllJournalsRequest({ limit }));
  }, [dispatch]);

  const latestJournals = allJournals?.slice(0, 4) || [];

  return (
    <div className="pt-4 pb-4">
      <h4 className="fs_25 fw-bold ff-gotham-bold">Dashboard</h4>
      <div className="bg_blue bg_image p-5 rounded-4 mt-3">
        <h4 className="fs_30 text-white ff-gotham-normal">Hi {userName}👋</h4>
        <h2 className="fs_50 ff-gotham-bold text-white mt-3 mb-0">
          Welcome to Coaching Room!
        </h2>
        <button
          style={{ borderRadius: "10px", height: "42px" }}
          className="mt-5 ff-gotham-normal border-0 bg-white px-5 fs_16 py-2"
        >
          Book Session
        </button>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-4 mb-3">
        <h4 className="fs_25 ff-gotham-bold fw-bold color_black2">
          Trending Journal Topics
        </h4>
        <Link
          to={"/coaching-dashboard/journal"}
          className="ff-gotham-bold color_blue text-decoration-underline"
        >
          View all
        </Link>
      </div>
      <Card className="rounded-5">
        <div className="row">
          {latestJournals.length > 0 ? (
            latestJournals.map((journal, index) => (
              <div className="col-md-3 col-sm-6" key={index}>
                <Link to={`/coaching-dashboard/journal/${journal?._id}`}>
                  <div className="position-relative">
                    <img
                      className="w-100"
                      src={journal?.images[0] || trending_image}
                      alt={journal?.title || "trending_image"}
                    />
                    <div className="position-absolute d-flex align-items-center gap-3 bottom-0 mb-2 ff-gotham-normal ps-3 text-white">
                      <p className="mb-0">{formatDate(journal?.createdAt)}</p>
                    </div>
                  </div>
                  <p className="ff-gotham-normal mt-3 fs_15 color_black4">
                    {truncateTitle(journal?.title, 10)}
                  </p>
                </Link>
              </div>
            ))
          ) : (
            <p>No journals found</p>
          )}
        </div>
      </Card>
    </div>
  );
};

export default CoachDashboard;
